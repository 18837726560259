export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "configurations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurations"])},
        "back_to_default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set all values back to default"])},
        "back_to_default_modal_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to set all values back to default?"])},
        "back_to_default_modal_cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
        "back_to_default_modal_yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, set back to default"])},
        "ratio_of_purified_water_to_oils": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ratio of purified water to oils"])},
        "purity_of_caustic_soda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purity of caustic soda"])},
        "usual_range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usually"])},
        "saponification_ratio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saponification ratio"])},
        "oils_to_be_used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight ratio of oils to be used"])},
        "all_clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set all values back to 0"])},
        "all_clear_modal_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to set all values back to 0?"])},
        "all_clear_modal_cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
        "all_clear_modal_yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, set back to 0"])},
        "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight of oils required"])},
        "amount_of_purified_water_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount of purified water required"])},
        "amount_of_caustic_soda_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount of caustic soda required"])},
        "amount_of_caustic_soda_water": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount of caustic soda water"])},
        "amount_of_oil_and_caustic_soda_water": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total amount of oils and caustic soda water"])},
        "volume_of_soap_to_be_made": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volume of soap to be made"])}
      },
      "jp": {
        "configurations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["設定"])},
        "back_to_default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全ての値を初期値に戻す"])},
        "back_to_default_modal_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本当に全ての値を初期値に戻してもよろしいですか？"])},
        "back_to_default_modal_cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャンセル"])},
        "back_to_default_modal_yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["はい"])},
        "ratio_of_purified_water_to_oils": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["油脂に対する精製水の割合"])},
        "purity_of_caustic_soda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["苛性ソーダの純度"])},
        "usual_range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通常"])},
        "saponification_ratio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["鹸化率"])},
        "oils_to_be_used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用するオイルの質量比"])},
        "all_clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全ての値を 0 に戻す"])},
        "all_clear_modal_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本当に全ての値を 0 に戻してもよろしいですか？"])},
        "all_clear_modal_cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャンセル"])},
        "all_clear_modal_yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["はい"])},
        "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["必要なオイルの質量"])},
        "amount_of_purified_water_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["必要な精製水の質量"])},
        "amount_of_caustic_soda_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["必要な苛性ソーダの質量"])},
        "amount_of_caustic_soda_water": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出来上がる苛性ソーダ水の質量"])},
        "amount_of_oil_and_caustic_soda_water": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["オイルと苛性ソーダ水の総合計"])},
        "volume_of_soap_to_be_made": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完成させたい石鹸の体積"])}
      }
    }
  })
}
