<template>
    <Menu />
    <div class="about">
        <p>{{ $t('under_construction') }}</p>
    </div>

<!--
    <div v-if="current_locale == 'en'">English</div>
    <div v-else>Japanese</div>
        
	<p>{{ t('aho') }}</p>
-->
	<Footer />
</template>

<script>
import Menu from '@/components/Menu.vue'
import Footer from '@/components/Footer.vue'
import { useI18n } from "vue-i18n"

export default {
    name: 'About us',
    data: function(){
        let vm = this
        return {

        }
    },
	setup() {
		const { t } = useI18n({
        inheritLocale: true,
            useScope: "local"
		})
		return { t };
	},
    computed: {
        current_locale: function(){
            return this.$i18n.locale
        }
    },
    components: {
        Menu,
		Footer,
    },
    created: function(){
        console.log(this.$i18n.locale)
    },
}
</script>

<i18n>
    {
      "en": {
        "aho": "aho in en"
      },
      "jp": {
        "aho": "aho in jp"
      },
    }
</i18n>
