<template>
<svg class="circle_flag" xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512" style="border-radius:50%"><path fill="#0052b4" d="M256 0h256v512H0V256Z"/><path fill="#eee" d="m400 256 5 17h18l-14 10 5 17-14-10-15 10 6-17-15-10h18zm40-78 7 15 16-4-7 15 13 10-16 3v17l-13-10-12 10v-17l-16-3 13-10-7-15 15 4zm-57-67 7 15 16-4-7 15 13 10-16 4v16l-13-10-12 10v-16l-16-4 12-10-7-15 16 4zm-65 89 7 15 16-4-7 15 12 10-15 4v16l-13-10-13 10v-16l-16-4 13-10-7-15 16 4zm65 156 7 15 16-4-7 15 13 10-16 3v17l-13-10-12 10v-17l-16-3 12-10-7-15 16 4zm-229-56 14 30 32-8-14 30 26 20-32 7v33l-26-21-25 21v-33l-32-7 26-20-14-30 31 8z"/><path fill="#eee" d="M0 0v32l32 32L0 96v160h32l32-32 32 32h32v-83l83 83h45l-8-16 8-15v-14l-83-83h83V96l-32-32 32-32V0H96L64 32 32 0Z"/><path fill="#d80027" d="M32 0v32H0v64h32v160h64V96h160V32H96V0H32zm96 128 128 128v-31l-97-97h-31z"/></svg>
</template>

<script>
export default {
   name: 'FlagAustralia',
}
</script>

<style scoped>
.circle_flag {
    width: 1.5em;
    height: 1.5em;
}
</style>

