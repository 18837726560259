<template>
	<svg class="circle_flag" xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512" style="border-radius:50%"><path fill="#eee" d="M0 0h512v512H0z"/><circle cx="256" cy="256" r="111.3" fill="#d80027"/></svg>
</template>

<script>
export default {
   name: 'FlagJapan',
}
</script>

<style scoped>
.circle_flag {
    width: 1.5em;
    height: 1.5em;
}
</style>

