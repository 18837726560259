<template>

    <div class="mb-1">
        <!-- Navbar -->
        <!-- nav class="navbar navbar-expand-lg navbar-light bg-light" -->
        <nav class="navbar navbar-expand-lg navbar-light bg-light">
                <div class="d-block d-lg-none brand">
                    <a href="/">
                        <img src="/static/images/logo-for-menu-without-perth.svg" height="50" alt="Cocosavon Logo" loading="lazy" />
                    </a>
                </div>
            <!-- Container wrapper -->

            <div class="container-fluid" style="padding-left: 0; padding-right: 0;">
                <!-- Toggle button -->
                <button class="navbar-toggler" type="button" data-mdb-toggle="collapse" data-mdb-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <i class="fas fa-bars"></i>
                </button>

                <!-- Right elements -->
                <div class="d-flex align-items-center order-lg-3">
					<div class="account_email">{{ accessing_user.email }}</div>
                    <LocaleSwitcher />
                    <!-- Icon -->
					<ShoppingCart class="right-menu-icon"/>
<!--
                    <a class="text-reset me-2" href="#">
                        <i class="fas fa-shopping-cart"></i>
                    </a>
-->

                    <!-- Notifications -->
<!--
                    <div class="dropdown">
                        <a class="text-reset me-2 dropdown-toggle hidden-arrow" href="#" id="navbarDropdownMenuLink" role="button" data-mdb-toggle="dropdown" aria-expanded="false">
                            <i class="fas fa-bell"></i>
                            <span class="badge rounded-pill badge-notification bg-danger">1</span>
                        </a>
                        <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdownMenuLink">
                            <li>
                                <a class="dropdown-item" href="#">Some news</a>
                            </li>
                            <li>
                                <a class="dropdown-item" href="#">Another news</a>
                            </li>
                            <li>
                                <a class="dropdown-item" href="#">Something else here</a>
                            </li>
                        </ul>
                    </div>
-->

                    <!-- Avatar -->
                    <div class="dropdown">
                        <a class="dropdown-toggle d-flex align-items-center hidden-arrow" href="#" id="navbarDropdownMenuAvatar" role="button" data-mdb-toggle="dropdown" aria-expanded="false">
							<UserIcon  class="right-menu-icon"/>
                        </a>
                        <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdownMenuAvatar">
                            <template v-for="menu_item in account_menu_items" :key="menu_item.name">
                                <li><a class="dropdown-item" :href="menu_item.path">{{ menu_item.display_name }}</a></li>
                            </template>
                        </ul>
                    </div>
                </div>
                <!-- Right elements -->

                <!-- Collapsible wrapper -->
                <div class="collapse navbar-collapse order-lg-2" id="navbarSupportedContent">
                    <!-- Navbar brand -->
                    <a class="navbar-brand mt-2 mt-lg-0 d-none d-lg-block" href="/">
                        <img src="/static/images/logo-for-menu-without-perth.svg" height="55" alt="Cocosavon Logo" loading="lazy" />
                    </a>
                    <!-- Left links -->
                    <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                        <li class="nav-item">
                            <a class="nav-link" href="/">Home</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="/shop/">Shop</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="/soap-calculator/">Soap Calculator</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="/about-us/">About</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="/contact-us/">Contact</a>
                        </li>
                    </ul>
                    <!-- Left links -->
                </div>
                <!-- Collapsible wrapper -->

            </div>
            <!-- Container wrapper -->
        </nav>
        <!-- Navbar -->
    </div>
</template>

<script>
import LocaleSwitcher from '@/components/LocaleSwitcher.vue'
import UserIcon from '@/assets/User.vue'
import ShoppingCart from '@/assets/ShoppingCart.vue'
import axios from "axios"

export default {
    name: 'Menu',
	data: function(){
		return {
			accessing_user: {
				id: null, 
				email: "Anonymous User",
				is_authenticated: false,
			},
            account_menu_items: [],
		}
	},
    components: {
        LocaleSwitcher,
		UserIcon,
		ShoppingCart,
    },
    created: function(){
        let vm = this

        const url = axios.get("/menu-items/")
            .then(res => {
                let basic_info = res.data.basic_info
                console.log({basic_info})
                vm.accessing_user = basic_info.accessing_user
                vm.account_menu_items = basic_info.menu_items
			})
	}
}
</script>

<style lang="css" scoped>
.navbar {
	padding: 1em 1em;
}
.brand {
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 1em;
}
.right-menu-icon {
	margin-right: 0.5em;
	color: dimgray;
}
.account_email {
    font-size: small;
    margin-right: 0.5em;
}
</style>
