<template>
    <Menu />
        <div class="container px-0" id="vue_component">
            <div class="row mt-4">
                <!---------------------------------------
                    Configurations
                ------------------------------------- -->
                <div class="col-md-6 col-lg-4">
                    <div class="card mb-2">
                        <div class="card-header configuration-color text-center white-text py-2" @click="configurationClicked">{{ t('configurations') }}</div>
                        <div class="card-body" id="configuration">
                            <div class="text-right mb-2">
                                <button type="button" class="btn btn-cocosavon-red btn-rounded" data-mdb-toggle="modal" data-mdb-target="#exampleModal" data-mdb-ripple-color="dark">
                                    <div>{{ t('back_to_default') }}</div>
                                </button>
                            </div>
                            <div class="container mb-2 bg-cocosavon-green rounded">
                                <div class="row configuration_title" @click="confClicked('percentage_of_water')">
                                    <div class="col-7 small pt-1 configuration-title">
                                        {{ t('ratio_of_purified_water_to_oils') }}<div class="small">{{ t('usual_range') }} 30 〜 40 %</div>
                                    </div>
                                    <div class="col-5 pt-1 text-right h1">
                                        <span class="oil_quantity">{{ percentage_of_water }}</span> <span class="small">%</span>
                                    </div>
                                </div>
                                <!-- div v-if="selected_percentage_of_water" class="row small" -->
                                <div class="row small" id="percentage_of_water_elem">
                                    <div class="col-2 mx-0 px-0 text-center" @click="configMinus1($event, 'percentage_of_water')">
                                        <button type="button" :class="minus_button_class" :style="minus_button_style">
                                            <div><i class="fa fa-minus-circle" aria-hidden="true"></i></div>
                                            <div>1</div>
                                        </button>
                                    </div>
                                    <div class="col-2 mx-0 px-0 text-center" @click="configPlus1($event, 'percentage_of_water')">
                                        <button type="button" :class="plus_button_class" :style="plus_button_style">
                                            <div><i class="fa fa-plus-circle" aria-hidden="true"></i></div>
                                            <div>1</div>
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div class="container mb-2 bg-cocosavon-green rounded">
                                <div class="row configuration_title" @click="confClicked('purity_of_naoh')">
                                    <div class="col-7 small pt-1">
                                        {{ t('purity_of_caustic_soda') }}<div class="small">{{ t('usual_range') }} 95 〜 100 %</div>
                                    </div>
                                    <div class="col-5 pt-1 text-right h1">
                                        <span class="oil_quantity">{{ purity_of_naoh }}</span> <span class="small">%</span>
                                    </div>
                                </div>
                                <!-- div v-if="selected_purity_of_naoh" class="row small" -->
                                <div class="row small" id="purity_of_naoh_elem">
                                    <div class="col-2 mx-0 px-0 text-center" @click="configMinus1($event, 'purity_of_naoh')">
                                        <button type="button" :class="minus_button_class" :style="minus_button_style">
                                            <div><i class="fa fa-minus-circle" aria-hidden="true"></i></div>
                                            <div>1</div>
                                        </button>
                                    </div>
                                    <div class="col-2 mx-0 px-0 text-center" @click="configPlus1($event, 'purity_of_naoh')">
                                        <button type="button" :class="plus_button_class" :style="plus_button_style">
                                            <div><i class="fa fa-plus-circle" aria-hidden="true"></i></div>
                                            <div>1</div>
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div class="container mb-2 bg-cocosavon-green rounded">
                                <div class="row configuration_title" @click="confClicked('saponification_rate')">
                                    <div class="col-7 small pt-1">
                                        {{ t('saponification_ratio') }}<div class="small">{{ t('usual_range') }} 85 〜 95 %</div>
                                    </div>
                                    <div class="col-5 pt-1 text-right h1">
                                        <span class="oil_quantity">{{ saponification_rate }}</span> <span class="small">%</span>
                                    </div>
                                </div>
                                <!-- div v-if="selected_saponification_rate" class="row small" -->
                                <div class="row small" id="saponification_rate_elem">
                                    <div class="col-2 mx-0 px-0 text-center" @click="configMinus1($event, 'saponification_rate')">
                                        <button type="button" :class="minus_button_class" :style="minus_button_style">
                                            <div><i class="fa fa-minus-circle" aria-hidden="true"></i></div>
                                            <div>1</div>
                                        </button>
                                    </div>
                                    <div class="col-2 mx-0 px-0 text-center" @click="configPlus1($event, 'saponification_rate')">
                                        <button type="button" :class="plus_button_class" :style="plus_button_style">
                                            <div><i class="fa fa-plus-circle" aria-hidden="true"></i></div>
                                            <div>1</div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-6 col-lg-4">
                    <!---------------------------------------
                    Volume to be made
                    ------------------------------------- -->
                    <div class="card mb-2">
                        <div class="card-header configuration-color text-center white-text py-2">{{ t('volume_of_soap_to_be_made') }}</div>
                        <div class="card-body">
                            <div class="container bg-cocosavon-green">
                                <div class="pt-1 text-right h1">
                                    <span>{{ volume_to_be_made }}</span> <span class="small">&#13220;</span>
                                </div>
                                <div class="row small calculator_panel" id="volume_to_be_made">
                                    <div class="col-2 mx-0 px-0 text-center" @click="minus_from_volume_to_be_made($event, 100)">
                                        <button type="button" :class="minus_button_class" :style="minus_button_style" data-mdb-ripple-color="dark">
                                            <div><i class="fa fa-minus-circle" aria-hidden="true"></i></div>
                                            <div>100</div>
                                        </button>
                                    </div>
                                    <div class="col-2 mx-0 px-0 text-center" @click="minus_from_volume_to_be_made($event, 10)">
                                        <button type="button" :class="minus_button_class" :style="minus_button_style" data-mdb-ripple-color="dark">
                                            <div><i class="fa fa-minus-circle" aria-hidden="true"></i></div>
                                            <div>10</div>
                                        </button>
                                    </div>
                                    <div class="col-2 mx-0 px-0 text-center" @click="minus_from_volume_to_be_made($event, 1)">
                                        <button type="button" :class="minus_button_class" :style="minus_button_style" data-mdb-ripple-color="dark">
                                            <div><i class="fa fa-minus-circle" aria-hidden="true"></i></div>
                                            <div>1</div>
                                        </button>
                                    </div>
                                    <div class="col-2 mx-0 px-0 text-center" @click="plus_to_volume_to_be_made($event, 1)">
                                        <button type="button" :class="plus_button_class" :style="plus_button_style" data-mdb-ripple-color="dark">
                                            <div><i class="fa fa-plus-circle" aria-hidden="true"></i></div>
                                            <div>1</div>
                                        </button>
                                    </div>
                                    <div class="col-2 mx-0 px-0 text-center" @click="plus_to_volume_to_be_made($event, 10)">
                                        <button type="button" :class="plus_button_class" :style="plus_button_style" data-mdb-ripple-color="dark">
                                            <div><i class="fa fa-plus-circle" aria-hidden="true"></i></div>
                                            <div>10</div>
                                        </button>
                                    </div>
                                    <div class="col-2 mx-0 px-0 text-center" @click="plus_to_volume_to_be_made($event, 100)">
                                        <button type="button" :class="plus_button_class" :style="plus_button_style" data-mdb-ripple-color="dark">
                                            <div><i class="fa fa-plus-circle" aria-hidden="true"></i></div>
                                            <div>100</div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!---------------------------------------
                        Oils to be used
                    ------------------------------------- -->
                    <div class="card mb-2">
                        <div class="card-header configuration-color text-center white-text py-2">{{ t('oils_to_be_used') }}</div>
                        <div class="card-body">
                            <div class="text-right mb-2">
                                <button type="button" class="btn btn-cocosavon-red btn-rounded" data-mdb-toggle="modal" data-mdb-target="#allClearModal" data-mdb-ripple-color="dark">
                                    <div>{{ t('all_clear') }}</div>
                                </button>
                            </div>
                            <div class="">
                                <transition name="baka" mode="out-in">
                                <div v-if="loading" key="3">
                                    <div class="text-center">
                                        <div class="spinner-border text-info" role="status">
                                              <span class="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                </div>
                                <div v-else key="4">
                                    <template v-for="oil in oils_array" :key="oil.id">
                                        <div :class="get_classes_for_oil_wrapper(oil)">
                                            <div class="oil_title row rounded" @click="oilTitleClicked($event, oil)" :key="oil.id">
                                                <div class="col-7 pt-1">
                                                    <template v-if="current_locale === 'jp'">
                                                        <div class="">{{ oil.name_jp }}</div>
                                                        <div class="small">({{ oil.name }})</div>
                                                    </template>
                                                    <template v-if="current_locale === 'en'">
                                                        <div class="">{{ oil.name }}</div>
                                                        <div class="small">({{ oil.name_jp }})</div>
                                                    </template>
                                                </div>
                                                <div v-if="oil.selected" class="col-5 pt-1 text-right h1">
                                                    <!-- span class="oil_quantity" :id="'oil_quantity_' + oil.id">{{ oil.quantity }}</span><span class="small">{{ oil.unit }}</span -->
                                                    <span class="oil_quantity" :id="'oil_quantity_' + oil.id">{{ oil.quantity }}</span><span class="small"></span>
                                                </div>
                                            </div>
                                            <div class="row small calculator_panel" :id="'calculator-' + oil.id" style="height: 0px;">
                                                <div class="col-2 mx-0 px-0 text-center" @click="minus100($event, oil)">
                                                    <button type="button" :class="minus_button_class" :style="minus_button_style" data-mdb-ripple-color="dark">
                                                        <div><i class="fa fa-minus-circle" aria-hidden="true"></i></div>
                                                        <div>100</div>
                                                    </button>
                                                </div>
                                                <div class="col-2 mx-0 px-0 text-center" @click="minus10($event, oil)">
                                                    <button type="button" :class="minus_button_class" :style="minus_button_style" data-mdb-ripple-color="dark">
                                                        <div><i class="fa fa-minus-circle" aria-hidden="true"></i></div>
                                                        <div>10</div>
                                                    </button>
                                                </div>
                                                <div class="col-2 mx-0 px-0 text-center" @click="minus1($event, oil)">
                                                    <button type="button" :class="minus_button_class" :style="minus_button_style" data-mdb-ripple-color="dark">
                                                        <div><i class="fa fa-minus-circle" aria-hidden="true"></i></div>
                                                        <div>1</div>
                                                    </button>
                                                </div>
                                                <div class="col-2 mx-0 px-0 text-center" @click="plus1($event, oil)">
                                                    <button type="button" :class="plus_button_class" :style="plus_button_style" data-mdb-ripple-color="dark">
                                                        <div><i class="fa fa-plus-circle" aria-hidden="true"></i></div>
                                                        <div>1</div>
                                                    </button>
                                                </div>
                                                <div class="col-2 mx-0 px-0 text-center" @click="plus10($event, oil)">
                                                    <button type="button" :class="plus_button_class" :style="plus_button_style" data-mdb-ripple-color="dark">
                                                        <div><i class="fa fa-plus-circle" aria-hidden="true"></i></div>
                                                        <div>10</div>
                                                    </button>
                                                </div>
                                                <div class="col-2 mx-0 px-0 text-center" @click="plus100($event, oil)">
                                                    <button type="button" :class="plus_button_class" :style="plus_button_style" data-mdb-ripple-color="dark">
                                                        <div><i class="fa fa-plus-circle" aria-hidden="true"></i></div>
                                                        <div>100</div>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                                </transition>
                            </div>
                        </div>
                    </div>
                </div>


                <!---------------------------------------
                    Summary
                ------------------------------------- -->
                <div class="col-md-6 col-lg-4">
                    <div class="card mb-2">
                        <div class="card-header required-color text-center white-text py-2">{{ t('summary') }}</div>
                        <div class="card-body">
                            <div class="text-left">
                                <template v-for="oil in oils_array">
                                    <template v-if="oil.selected && oil.quantity > 0">
                                        <div class="row rounded" :key="oil.id">
                                            <div class="col-7 pt-1">
                                                <template v-if="current_locale === 'jp'">
                                                    <div class="">{{ oil.name_jp }}</div>
                                                    <div class="small">({{ oil.name }})</div>
                                                </template>
                                                <template v-if="current_locale === 'en'">
                                                    <div class="">{{ oil.name }}</div>
                                                    <div class="small">({{ oil.name_jp }})</div>
                                                </template>
                                            </div>
                                            <div class="col-5 pt-1 text-right h1">
                                                <!-- {{ oil.quantity }} <span class="small">{{ oil.unit }}</span> -->
                                                {{ (oil.quantity * ratio_to_make_volume).toFixed(0) }} <span class="small">{{ oil.unit }}</span>
                                            </div>
                                            <!-- span>{{ (oil.quantity * ratio_to_make_volume).toFixed(0) }} [g]</span -->
                                        </div>
                                    </template>
                                </template>
                                <!--
                                <div class="text-right display-4">
                                    {{ oil_amount_g }} ga
                                </div>
                                -->
                            </div>
                        </div>
                    </div>

                    <div class="card mb-2">
                        <div class="card-header required-color text-center white-text py-2">{{ t('amount_of_purified_water_required') }}</div>
                        <div class="card-body">
                            <!-- div class="text-right display-4">
                                {{ water_amount_g.toFixed(0) }} g
                            </div -->
                            <div class="text-right h1">
                                <!-- {{ (water_amount_g * ratio_to_make_volume).toFixed(0) }} <span class="small">g</span>-->
                                {{ amount_of_purified_water_required.toFixed(0) }} <span class="small">g</span>
                            </div>
                        </div>
                    </div>

                    <div class="card mb-2">
                        <div class="card-header required-color text-center white-text py-2">{{ t('amount_of_caustic_soda_required') }}</div>
                        <div class="card-body">
                            <!-- div class="text-right display-4">
                                {{ naoh_amount_g.toFixed(0) }} g
                            </div -->
                            <div class="text-right h1">
                                {{ amount_of_caustic_soda_required.toFixed(0) }} <span class="small">g</span>
                            </div>

                        </div>
                    </div>

                    <!--
                    <div class="card mb-2">
                        <div class="card-header result-color text-center white-text py-2">{{ t('amount_of_caustic_soda_water') }}</div>
                        <div class="card-body">
                            <div class="text-right display-4">
                                {{ naoh_amount_plus_water_amount_g.toFixed(0) }} g
                            </div>
                            {{ concentration_of_naoh_solution }} %
                        </div>
                    </div>

                    <div class="card mb-2">
                        <div class="card-header result-color text-center white-text py-2">{{ t('amount_of_oil_and_caustic_soda_water') }}</div>
                        <div class="card-body">
                            <div class="text-right display-4">
                                {{ oil_amount_plus_naoh_amount_plus_water_amount_cc.toFixed(0) }} ml
                            </div>
                            <div class="text-right display-4">
                                {{ oil_amount_plus_naoh_amount_plus_water_amount_g.toFixed(0) }} g
                            </div>
                        </div>
                    </div>
                    -->
                </div>
            </div>
        </div>

<!-- Button trigger modal -->

	<!-- Modal -->
	<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title" id="exampleModalLabel">{{ t('back_to_default') }}</h5>
					<button type="button" class="btn-close" data-mdb-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body">{{ t('back_to_default_modal_body') }}</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-cocosavon-green" data-mdb-dismiss="modal">{{ t('back_to_default_modal_cancel') }}</button>
					<button type="button" class="btn btn-cocosavon-red" @click="setToDefaultValues">{{ t('back_to_default_modal_yes') }}</button>
				</div>
			</div>
		</div>
	</div>

	<!-- Modal -->
	<div class="modal fade" id="allClearModal" tabindex="-1" aria-labelledby="allClearLabel" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title" id="allClearLabel">{{ t('all_clear') }}</h5>
					<button type="button" class="btn-close" data-mdb-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body">{{ t('all_clear_modal_body') }}</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-cocosavon-green" data-mdb-dismiss="modal">{{ t('all_clear_modal_cancel') }}</button>
					<button type="button" class="btn btn-cocosavon-red" @click="allClear">{{ t('all_clear_modal_yes') }}</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Menu from '@/components/Menu.vue'
import $ from 'jquery'
import axios from "axios"
import * as mdb from 'mdb-ui-kit'
import { useI18n } from "vue-i18n"

export default {
    name: 'Soap Calculator',
	setup() {
		const { t } = useI18n({
        inheritLocale: true,
            useScope: "local"
		})
		return { t };
	},
    components: {
        Menu
    },
    data: function(){
        let percentage_of_water_default = 34 // [%] 30 to 40,
        let purity_of_naoh_default = 98 // [%] 95 to 100
        let saponification_rate_default = 92 // [%] 85 to 95

        return {
            loading: false,
            oils_array: [],
            currently_being_input_oil: null,
            ratio_koh_to_naoh: 120.0 / 168,
            unit_g: 'g',
            //unit_cc: 'cc',
            oil_specific_weight: 0.9,

            percentage_of_water_default: percentage_of_water_default,
            purity_of_naoh_default: purity_of_naoh_default,
            saponification_rate_default: saponification_rate_default,

            percentage_of_water: percentage_of_water_default,
            purity_of_naoh: purity_of_naoh_default,
            saponification_rate: saponification_rate_default,
            specific_gravity_of_naoh_solution: 1.3,  // NaOH水溶液の比重

            // Other configurations
            configurationShown: null,
            selected_percentage_of_water: false,
            selected_purity_of_naoh: false,
            selected_saponification_rate: false,
            percentage_of_water_range: [20, 40],
            purity_of_naoh_range: [95, 100],
            saponification_rate_range: [85, 97],

            //minus_button_class: "btn btn-info",
            //minus_button_style: "background-color: peru; padding: 0.5em; width: 100%; border-radius: 0;",
            minus_button_class: "btn btn-cocosavon-yellow",
            minus_button_style: "padding: 0.5em; width: 100%; border-radius: 0;",
            //plus_button_class: "btn btn-info",
            //plus_button_style: "background-color: darkcyan; padding: 0.5em; width: 100%; border-radius: 0;",
            plus_button_class: "btn btn-cocosavon-blue",
            plus_button_style: "padding: 0.5em; width: 100%; border-radius: 0;",

            volume_to_be_made: 0,
        }
    },
    created: function(){
        let vm = this
        vm.loading = true
        let oil = {}

        const url = axios.get("/oil/")
            .then(res => {
                for (let i=0; i<res.data.length; i++){
                    let an_oil = res.data[i]

                    // Not from DB
                    an_oil.selected = false
                    an_oil.quantity = 0
                    an_oil.unit = vm.unit_g  // Because the saponification_number is based on [g], this unit should be [g]

                    vm.oils_array.push(an_oil)
                }
            })
            .catch(err => {
                console.log("err:", err);
            });

        vm.loading = false
    },
    mounted: function(){
        this.$nextTick(() => {
            this.configurationShown = true
            this.applyRippleEffect()
            this.show_hide_conf_percentage_of_water()
            this.show_hide_conf_purity_of_naoh()
            this.show_hide_conf_saponification_rate()
        });
    },
    watch: {
        configurationShown: function(){
            let el_config = $('#configuration')
            if (this.configurationShown){
                el_config.slideDown()
            } else {
                el_config.slideUp()
            }
        }
    },
    computed: {
        amount_of_caustic_soda_required: function(){
            if (Number.isFinite(this.ratio_to_make_volume)){
                return this.naoh_amount_g * this.ratio_to_make_volume
            } else {
                return 0
            }
        },
        amount_of_purified_water_required: function(){
            if (Number.isFinite(this.ratio_to_make_volume)){
                return this.water_amount_g * this.ratio_to_make_volume
            } else {
                return 0
            }
        },
        current_locale: function(){
            return this.$i18n.locale
        },
        oil_amount_cc: function() {
            let oil_amount_cc = 0
            for (let i=0; i<this.oils_array.length; i++){
                if (this.oils_array[i].selected){
                    let quantity = this.oils_array[i].quantity
                    if (this.oils_array[i].unit == this.unit_g){
                        // If the unit of the amount of oil is volume [g]
                        quantity = quantity / this.oil_specific_weight
                    }
                    oil_amount_cc = oil_amount_cc + parseFloat(quantity)
                }
            }
            return oil_amount_cc
        },
        oil_water_amount_cc: function() {
            return this.oil_amount_cc + this.water_amount_g
        },
        oil_amount_plus_naoh_amount_plus_water_amount_cc: function(){
            return this.oil_amount_cc + (this.naoh_amount_g + this.water_amount_g) / this.specific_gravity_of_naoh_solution
        },
        ratio_to_make_volume: function() {
            return this.volume_to_be_made / this.oil_amount_plus_naoh_amount_plus_water_amount_cc
        },
        oil_amount_g: function() {
            let oil_amount_g = 0
            for (let i=0; i<this.oils_array.length; i++){
                if (this.oils_array[i].selected){
                    let quantity = this.oils_array[i].quantity
                    //if (this.oils_array[i].unit == this.unit_cc){
                    //    // If the unit of the amount of oil is volume [cc]
                    //    quantity = quantity * this.oil_specific_weight
                    //}
                    oil_amount_g = oil_amount_g + parseFloat(quantity)
                }
            }
            return oil_amount_g
        },
        naoh_amount_g: function() {
            let naoh_amount_g = 0
            for (let i=0; i<this.oils_array.length; i++){
                if (this.oils_array[i].selected){
                    let quantity_g = this.oils_array[i].quantity
                    //if (this.oils_array[i].unit == this.unit_cc){
                    //    quantity_g = quantity_g * this.oil_specific_weight
                    //}
                    naoh_amount_g = naoh_amount_g + parseFloat(quantity_g) * this.oils_array[i].saponification_number / 1000.0 * this.ratio_koh_to_naoh
                }
            }
            return naoh_amount_g / (this.purity_of_naoh / 100.0) * (this.saponification_rate / 100.0)
        },
        water_amount_g: function() {
            let water_amount_g = this.oil_amount_g * this.percentage_of_water / 100.0
            return water_amount_g
        },
        naoh_amount_plus_water_amount_g: function(){
            return this.naoh_amount_g + this.water_amount_g
        },
        oil_amount_plus_naoh_amount_plus_water_amount_g: function(){
            return this.naoh_amount_plus_water_amount_g + this.oil_amount_g
        },
        concentration_of_naoh_solution: function() {
            return this.naoh_amount_g / this.naoh_amount_plus_water_amount_g * 100
        }
    },
    methods: {
        setToDefaultValues: function(){
            this.percentage_of_water = this.percentage_of_water_default
            this.purity_of_naoh = this.purity_of_naoh_default
            this.saponification_rate = this.saponification_rate_default
        },
        allClear: function(){
            for (let i=0; i<this.oils_array.length; i++){
                this.oils_array[i].quantity = 0
            }
        },
        applyRippleEffect: function(){
            document.querySelectorAll('.oil_title, configuration-title').forEach((cardRipple) => {
                new mdb.Ripple(cardRipple, {
                    color: 'light'
                })
            });
        },
        get_classes_for_oil_wrapper: function(oil){
            let base_classes = 'container mb-2 oil_wrapper '
            if (oil.selected){
                if (oil == this.currently_being_input_oil){
                    return base_classes + 'bg-being-input bg-cocosavon-green'
                } else {
                    return base_classes + 'bg-selected bg-cocosavon-green'
                }
            } else {
                return base_classes + 'oil_deselected'
            }
        },
        configPlus1: function(e, config_name){
            if (config_name === 'percentage_of_water'){
                this.percentage_of_water += 1
                if (this.percentage_of_water > Math.max(...this.percentage_of_water_range)){
                    this.percentage_of_water = Math.max(...this.percentage_of_water_range)
                }
            } else if (config_name === 'purity_of_naoh'){
                this.purity_of_naoh += 1
                if (this.purity_of_naoh > Math.max(...this.purity_of_naoh_range)){
                    this.purity_of_naoh= Math.max(...this.purity_of_naoh_range)
                }
            } else if (config_name === 'saponification_rate'){
                this.saponification_rate += 1
                if (this.saponification_rate > Math.max(...this.saponification_rate_range)){
                    this.saponification_rate= Math.max(...this.saponification_rate_range)
                }
            }
        },
        configMinus1: function(e, config_name){
            if (config_name === 'percentage_of_water'){
                this.percentage_of_water -= 1
                if (this.percentage_of_water < Math.min(...this.percentage_of_water_range)){
                    this.percentage_of_water = Math.min(...this.percentage_of_water_range)
                }
            } else if (config_name === 'purity_of_naoh'){
                this.purity_of_naoh -= 1
                if (this.purity_of_naoh < Math.min(...this.purity_of_naoh_range)){
                    this.purity_of_naoh= Math.min(...this.purity_of_naoh_range)
                }
            } else if (config_name === 'saponification_rate'){
                this.saponification_rate -= 1
                if (this.saponification_rate < Math.min(...this.saponification_rate_range)){
                    this.saponification_rate= Math.min(...this.saponification_rate_range)
                }
            }
        },
        plus_or_minus_clicked: function(oil_id){
            let number_text = document.querySelector('#oil_quantity_' + oil_id);
            number_text.classList.add('highlight');
            // remove it after 50ms
            setTimeout(function() {
                number_text.classList.remove('highlight');
            }, 50);
        },
        plus100: function(e, oil){
            oil.quantity = oil.quantity + 100
            this.currently_being_input_oil = oil
            this.plus_or_minus_clicked(oil.id)
        },
        plus10: function(e, oil){
            oil.quantity = oil.quantity + 10
            this.currently_being_input_oil = oil
            this.plus_or_minus_clicked(oil.id)
        },
        plus1: function(e, oil){
            oil.quantity = oil.quantity + 1
            this.currently_being_input_oil = oil
            this.plus_or_minus_clicked(oil.id)
        },
        minus_from_volume_to_be_made: function(e, amount){
            this.volume_to_be_made = this.volume_to_be_made - amount
            if (this.volume_to_be_made < 0){
                this.volume_to_be_made = 0
            }
        },
        plus_to_volume_to_be_made: function(e, amount){
            this.volume_to_be_made = this.volume_to_be_made + amount
        },
        minus100: function(e, oil){
            oil.quantity = oil.quantity - 100
            if (oil.quantity < 0){
                oil.quantity = 0
            }
            this.currently_being_input_oil = oil
            this.plus_or_minus_clicked(oil.id)
        },
        minus10: function(e, oil){
            oil.quantity = oil.quantity - 10
            if (oil.quantity < 0){
                oil.quantity = 0
            }
            this.currently_being_input_oil = oil
            this.plus_or_minus_clicked(oil.id)
        },
        minus1: function(e, oil){
            oil.quantity = oil.quantity - 1
            if (oil.quantity < 0){
                oil.quantity = 0
            }
            this.currently_being_input_oil = oil
            this.plus_or_minus_clicked(oil.id)
        },
        show_hide_conf_percentage_of_water: function(){
            let calc_elem = document.getElementById('percentage_of_water_elem');
            calc_elem.style.transition = "all 0.4s ease-in-out";
            calc_elem.style.overflowY = "hidden";
            if(this.selected_percentage_of_water){
                calc_elem.style.height = "48px";
            } else {
                calc_elem.style.height = "0px";
            }
        },
        show_hide_conf_purity_of_naoh: function(){
            let calc_elem = document.getElementById('purity_of_naoh_elem');
            calc_elem.style.transition = "all 0.4s ease-in-out";
            calc_elem.style.overflowY = "hidden";
            if(this.selected_purity_of_naoh){
                calc_elem.style.height = "48px";
            } else {
                calc_elem.style.height = "0px";
            }
        },
        show_hide_conf_saponification_rate: function(){
            let calc_elem = document.getElementById('saponification_rate_elem');
            calc_elem.style.transition = "all 0.4s ease-in-out";
            calc_elem.style.overflowY = "hidden";
            if(this.selected_saponification_rate){
                calc_elem.style.height = "48px";
            } else {
                calc_elem.style.height = "0px";
            }
        },
        confClicked: function(para) {
            if(para === 'percentage_of_water'){
                this.selected_percentage_of_water = !this.selected_percentage_of_water
                this.show_hide_conf_percentage_of_water()
            } else if (para === 'purity_of_naoh'){
                this.selected_purity_of_naoh = !this.selected_purity_of_naoh
                this.show_hide_conf_purity_of_naoh()
            } else if (para === 'saponification_rate'){
                this.selected_saponification_rate = !this.selected_saponification_rate
                this.show_hide_conf_saponification_rate()
            }
        },
        configurationClicked: function() {
            this.configurationShown = !this.configurationShown
        },
        oilCloseClicked: function(e, oil){
            if(oil.selected){
                //Vue.set(oil, 'selected', false)
                oil.selected = false
            }
            e.stopPropagation()
        },
        oilTitleClicked: function(e, oil){
            oil.selected = !oil.selected
            let calc_elem = document.getElementById('calculator-' + oil.id);
            calc_elem.style.transition = "all 0.4s ease-in-out";
            calc_elem.style.overflowY = "hidden";

            if(oil.selected){
                this.currently_being_input_oil = oil
                calc_elem.style.height = "48px";

            } else {
                calc_elem.style.height = "0px";
            }
        },
        containsObject: function(obj, arr) {
            var i;
            for (i = 0; i < arr.length; i++) {
                if (arr[i].name === obj.name) {
                    return true;
                }
            }
            return false;
        },
    },
}
</script>

<i18n>
    {
      "en": {
        "configurations": "Configurations",
        "back_to_default": "Set all values back to default",
        "back_to_default_modal_body": "Are you sure you want to set all values back to default?",
        "back_to_default_modal_cancel": "Cancel",
        "back_to_default_modal_yes": "Yes, set back to default",
        "ratio_of_purified_water_to_oils": "Ratio of purified water to oils",
        "purity_of_caustic_soda": "Purity of caustic soda",
        "usual_range": "Usually",
        "saponification_ratio": "Saponification ratio",
        "oils_to_be_used": "Weight ratio of oils to be used",
        "all_clear": "Set all values back to 0",
        "all_clear_modal_body": "Are you sure you want to set all values back to 0?",
        "all_clear_modal_cancel": "Cancel",
        "all_clear_modal_yes": "Yes, set back to 0",
        "summary": "Weight of oils required",
        "amount_of_purified_water_required": "Amount of purified water required",
        "amount_of_caustic_soda_required": "Amount of caustic soda required",
        "amount_of_caustic_soda_water": "Amount of caustic soda water",
        "amount_of_oil_and_caustic_soda_water": "Total amount of oils and caustic soda water",
        "volume_of_soap_to_be_made": "Volume of soap to be made",
      },
      "jp": {
        "configurations": "設定",
        "back_to_default": "全ての値を初期値に戻す",
        "back_to_default_modal_body": "本当に全ての値を初期値に戻してもよろしいですか？",
        "back_to_default_modal_cancel": "キャンセル",
        "back_to_default_modal_yes": "はい",
        "ratio_of_purified_water_to_oils": "油脂に対する精製水の割合",
        "purity_of_caustic_soda": "苛性ソーダの純度",
        "usual_range": "通常",
        "saponification_ratio": "鹸化率",
        "oils_to_be_used": "使用するオイルの質量比",
        "all_clear": "全ての値を 0 に戻す",
        "all_clear_modal_body": "本当に全ての値を 0 に戻してもよろしいですか？",
        "all_clear_modal_cancel": "キャンセル",
        "all_clear_modal_yes": "はい",
        "summary": "必要なオイルの質量",
        "amount_of_purified_water_required": "必要な精製水の質量",
        "amount_of_caustic_soda_required": "必要な苛性ソーダの質量",
        "amount_of_caustic_soda_water": "出来上がる苛性ソーダ水の質量",
        "amount_of_oil_and_caustic_soda_water": "オイルと苛性ソーダ水の総合計",
        "volume_of_soap_to_be_made": "完成させたい石鹸の体積",
      },
    }
</i18n>

<style lang="css" scoped>
    /*
    @import '~mdb-ui-kit/css/mdb.min.css';
    */

    body {
        margin: 0;
        /*background: url("./bg-pattern.png") repeat;*/
        background: #fff9e0;
        color: grey;
    }
    .main-title {
        /* font-family: 'Comfortaa', cursive;
        font-family: 'Varela Round', sans-serif; */
        font-family: 'Fredericka the Great', cursive;
        font-size: 2em;
    }
    .main-color {
        color: #e9967a;
    }
    .bg-selected {
/*
        background-color: rgba(0, 188, 212, 0.3);
*/
    }
    .bg-selected-for-name {
/*
        background-color: rgba(0, 188, 212, 0.3);
*/
        cursor: pointer;
    }
    .bg-being-input {
/*
        background-color: rgba(0, 188, 212, 0.3);
*/
        border-top: solid 5px rgba(0, 120, 150, 0.5);
    }
    .bg-being-input-for-name {
/*
        background-color: rgba(0, 188, 212, 0.3);
*/
        cursor: pointer;
    }
    .text-right {
        text-align: right;
    }
    .required-color {
        background-color: darkorange;
    }
    .result-color {
        background-color: lightseagreen;
    }
    .configuration-color {
        background-color: darkgray;
    }
    .oil_wrapper {
        position: relative;
        /* margin: 1em 0 0 0;*/
    }
    .oil_close_icon {
        position: absolute;
        right: -1em;
        top: -0.6em;
        color: crimson;
        z-index: 1000;
    }
    .aho-enter-active, .aho-leave-active .baka-enter-active, .baka-leave-active {
          transition: opacity .7s
    }
    .aho-enter, .aho-leave-to .baka-enter, .baka-leave-to {
            opacity: 0
    }
    .oil_quantity {
        /* transition: font-size 1s; */
        transition: color 0.6s;
    }
    .highlight {
        /*
        font-size: 1.2em;
        transition: font-size 0s;
        */
        color: darkcyan;
        transition: color 0s;
    }
    .oil_deselected {
        background-color: lightgray;
    }
    .calculator_panel {
        transition: all 0.4s ease-in-out;
        overflow-y: hidden;
    }
    .oil_title, .configuration_title {
        cursor: pointer;
    }
</style>

