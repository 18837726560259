import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import i18n from './i18n'
import { VueReCaptcha } from 'vue-recaptcha-v3'

//require('../node_modules/bootstrap/dist/css/bootstrap.css' );
//import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
//require('../node_modules/@fortawesome/fontawesome-free/css/fontawesome.min.css')
//import '@/../node_modules/@fortawesome/fontawesome-free/css/fontawesome.min.css'
//import '@/../node_modules/@fortawesome/fontawesome-free/css/all.min.css'
//import '@/../node_modules/bootstrap/dist/css/bootstrap.min.css'
//import $ from 'jquery'
//import 'mdb-vue-ui-kit/css/mdb.min.css';

//window.jQuery = require('jquery');


createApp(App)
    .use(i18n)
    .use(router)
    .use(VueReCaptcha, { siteKey: '6LcDu_cgAAAAANhZk4oYzCEObCfPr6T86rhxc-H6' })
    .mount('#app')
