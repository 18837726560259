<template>
    <div>
        Recaptcha.vue
		<button @click="recaptcha">Execute recaptcha</button>
    </div>
</template>

<script>
import axios from "axios"
import $ from 'jquery'

export default {
    name: 'Recaptcha',
    components: {
        //VueReCaptcha
    },
    data: function(){
        return {
            site_key: '6LdFDvUgAAAAAGQu_RyIHD7-3eBf1PkM1JwGlIp9'
        }
    },
	methods: {
		async recaptcha() {
		// (optional) Wait until recaptcha has been loaded.
		await this.$recaptchaLoaded()

		// Execute reCAPTCHA with action "login".
		const token = await this.$recaptcha('login')
		console.log({token})
        //const response = await axios.post("https://www.google.com/recaptcha/api/siteverify", {
		//	'response': token,
		//	'secret': '6LcDu_cgAAAAAMYDdHuzEWkuRq3oFXYhMoI8Mcn3'
		//})

		// Do stuff with the received token.
        const res = await axios.post("/contact/", {'token': token})

		}
	},
    created: async function(){
    },
    mounted: async function(){
    },
}
</script>


