import { createRouter, createWebHistory } from 'vue-router'
import Home from '../components/Home.vue'
import Shop from '../components/Shop.vue'
import AboutUs from '../components/AboutUs.vue'
import SoapCalculator from '../components/Calculator.vue'
import ContactUs from '../components/ContactUs.vue'
import AccountsPage from '../components/AccountsPage.vue'
import UsersPage from '../components/UsersPage.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/shop',
    name: 'Shop',
    component: Shop,
  },
  {
    path: '/about-us',
    name: 'About Us',
    component: AboutUs,
  },
  {
    path: '/soap-calculator',
    name: 'Soap Calculator',
    component: SoapCalculator,
  },
  {
    path: '/contact-us',
    name: 'Contact Us',
    component: ContactUs,
  },
  {
    // For all the paths under accounts
    // Example, /accounts/login, /account/logout, etc
    path: "/accounts/:catchAll(.*)",
    name: 'Account Page',
    component: AccountsPage,
  },
  {
    // For all the paths under accounts
    // Example, /accounts/login, /account/logout, etc
    path: "/users/:catchAll(.*)",
    name: 'Users Page',
    component: UsersPage,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
