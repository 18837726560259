<template>
    <Menu />
    <div class="about">
        <Recaptcha />
		under construction
    </div>
	<Footer />
</template>

<script>
import Menu from '@/components/Menu.vue'
import Footer from '@/components/Footer.vue'
import Recaptcha from '@/components/Recaptcha.vue'

export default {
    name: 'Shop',
    components: {
        Menu,
		Footer,
        Recaptcha,
    }
}
</script>
