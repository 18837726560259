<template>
    <div class="user_icon">
        <i class="fas fa-user-alt"></i>
    </div>
</template>

<script>
export default {
   name: 'UserIcon',
}
</script>

<style scoped>
.user_icon {
    font-size: large;
}
</style>

