<template>
    <Menu />
    <div id="insert_django_contents"></div>
	<div class="image_wrapper">
		<img src="/static/images/cocosavon-soap-2048.webp" class="d-block w-100 image_on_home_page" alt="Cocosavon soap" />
		<img class="overlay_text" src="/static/images/logo-for-overlay.svg" />
	</div>
	<Footer />
</template>

<script>
import Menu from '@/components/Menu.vue'
import Footer from '@/components/Footer.vue'
import jQuery from 'jquery'

export default {
    name: 'Home',
    components: {
        Menu,
        Footer,
    },
    methods: {
        move_main_elem: function(){
            // Select the contents generated and inserted above the #app element by django
            let main_elem = jQuery("#contents_from_django")
            // Insert the contents selected above into the vue components
            main_elem.appendTo("#insert_django_contents")
        }
    },
    mounted: function(){
        this.move_main_elem()
    },
}
</script>

<style lang="css" scoped>
.image_wrapper {
	position: relative;
}
.overlay_text {
	position: absolute;
	top: 50%;
	left: 60%;
	width: 10%;
	color: white;
	min-width: 100px;
}
.image_on_home_page {
    display: block;
    height: 500px;
    object-fit: cover;
	object-position: 40% 50%;
    width: 100%;
}
</style>
