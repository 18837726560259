<template>
	<div @click="toggleLanguage" class="local-switcher-wrapper">
		<template v-if="$i18n.locale === 'en'">
			<AuFlag/>
		</template>
		<template v-if="$i18n.locale === 'jp'">
			<JpFlag/>
		</template>
	</div>
</template>

<script>
import JpFlag from '@/assets/JpFlag.vue'
import AuFlag from '@/assets/AuFlag.vue'

export default {
    name: "LocaleSwitcher",
    data() {
        //return { locales: ["en", "jp"] };
        return { 
			locales: [
				{
					"id": "en",
					"text": "English",
				},
				{
					"id": "jp",
					"text": "Japanese",
				},
			]
		};
    },
	components: {
		JpFlag,
		AuFlag,
	},
	methods: {
		setLocale(locale_id){
			this.$i18n.locale = locale_id
		},
		toggleLanguage(){
			this.$i18n.locale = (this.$i18n.locale==="en") ? "jp" : "en"
		}
	},
    computed: {
    }
};
</script>

<style scoped>
.local-switcher-wrapper {
	margin-right: 0.5em;
	cursor: pointer;
}
</style>
