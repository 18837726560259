<template>
    <Menu />
    <div class="container">
		<!-- Card -->
        <div class="row mt-4">
            <template v-for="soap in soaps" :key="soap.id">
                <div class="col-md-6 col-lg-4">
                    <div class="card">

                        <!-- Card image -->
                        <template v-if="soap.soap_images.length > 0">
                            <a href="#">
                                <img class="card-img-top" :src="soap.soap_images[0].image" :alt="'Soap image' + soap.name">
                            </a>
                        </template>
                        <template v-else>
                            <img class="card-img-top" src="/static/images/soap-image-placeholder.png" alt="Card image cap">
                        </template>

                        <!-- Card content -->
                        <div class="card-body">

                            <!-- Title -->
                            <h4 class="card-title"><a>{{ soap.name }}</a></h4>
                            <!-- Text -->
                            <p class="card-text">{{ soap.description }}</p>
                            <!-- Button -->
                            <button href="#" class="btn btn-primary disabled">Add to Cart</button>

                        </div>

                    </div>
                </div>
            </template>
        </div>
		<!-- Card -->

    </div>
	<Footer />
</template>

<script>
import Menu from '@/components/Menu.vue'
import Footer from '@/components/Footer.vue'
import axios from "axios"

export default {
    name: 'Shop',
    components: {
        Menu,
		Footer,
    },
    data: function(){
        return {
            soaps: []
        }
    },
    created: function() {
        let vm = this
        const url = axios.get("/api/soaps/")
            .then(res => {
                for (let i=0; i<res.data.length; i++){
                    let soap = res.data[i]
                    vm.soaps.push(soap)
                }
            })
            .catch(err => {
                console.log("err:", err);
            });
    }
}
</script>
