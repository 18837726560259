<template>
    <Menu />
    <div id="insert_django_contents"></div>
	<Footer />
</template>

<script>
import Menu from '@/components/Menu.vue'
import Footer from '@/components/Footer.vue'
import { useI18n } from "vue-i18n"
import jQuery from 'jquery'

export default {
    name: 'UsersPage',
    data: function(){
        let vm = this
        return {

        }
    },
	setup() {
		const { t } = useI18n({
        inheritLocale: true,
            useScope: "local"
		})
		return { t };
	},
    computed: {
        current_locale: function(){
            return this.$i18n.locale
        }
    },
    components: {
        Menu,
		Footer,
    },
    methods: {
        move_main_elem: function(){
            // Select the contents generated and inserted above the #app element by django
            let main_elem = jQuery("#contents_from_django")
            // Insert the contents selected above into the vue components
            main_elem.appendTo("#insert_django_contents")
        }
    },
    mounted: function(){
        this.move_main_elem()
    },
}
</script>

<i18n>
    {
      "en": {
        "aho": "aho in en"
      },
      "jp": {
        "aho": "aho in jp"
      },
    }
</i18n>
